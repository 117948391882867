'use strict'

export default class CacheService {

    constructor() {
        this.isCacheAPISupported = 'caches' in window && window.isSecureContext;
        this.cacheName = 'cache-service';
    }

    async setCache(key, data, ttl = 3 * 60 * 1000) { // cached for 3 minutes in miliseconds
        const now = Date.now();
        const item = {
            data: data,
            expiry: now + ttl,
        };
        if (this.isCacheAPISupported) {
            const cache = await caches.open(this.cacheName);
            const response = new Response(JSON.stringify(item), {
                headers: { 'Content-Type': 'application/json' },
            });
            await cache.put(key, response);
        } else {
            try {
                localStorage.setItem(key, JSON.stringify(item));
            } catch (e) {
                console.error('LocalStorage Save Error:', e);
            }
        }
    }

    async getCache(key) {
        const now = Date.now();

        if (this.isCacheAPISupported) {
            const cache = await caches.open(this.cacheName);
            const cachedResponse = await cache.match(key);

            if (cachedResponse) {
                const item = await cachedResponse.json();
                if (now > item.expiry) {
                    // If expired, delete the cache entry and return null
                    await cache.delete(key);
                    return null;
                }
                return item.data;
            }
            return null; // Cache miss
        } else {
            const itemStr = localStorage.getItem(key);
            // If the item doesn't exist, return null
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            // Compare the expiry time of the item with the current time
            if (now > item.expiry) {
                // If the item is expired, delete the item from storage and return null
                localStorage.removeItem(key);
                return null;
            }
            return item.data;
        }
    }

}
